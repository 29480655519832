<template>
  <v-card class="my-4">
    <v-card-title class="headline text-center justify-center">
      CBVB & BVB Mapping
    </v-card-title>
    <v-divider></v-divider>
    <!-- Profile -->
    <v-card-text>
      <v-row dense>
        <!-- ADDITIONAL REGISTRATION INFO -->
        <v-col cols="12" md="4">
          <v-text-field
          label="CBVB Player Id"
          v-model="map.cbvbId"
          color="color3"
          :disabled="map.id === null"
        ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
          label="BVB Player Id"
          v-model="map.bvbId"
          color="color3"
          :disabled="map.id === null"
        ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn color="success"
            fab
            small
            :disabled="!dirty"
            :loading="loading"
            @click.stop="save"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['id'],
  data () {
    return {
      map: {
        id: null,
        cbvbId: null,
        bvbId: null
      },
      mapOg: {
        id: null,
        cbvbId: null,
        bvbId: null
      },
      loading: false
    }
  },
  computed: {
    dirty () {
      return this.map.cbvbId !== this.mapOg.cbvbId || this.map.bvbId !== this.mapOg.bvbId
    }
  },
  methods: {
    loadMe () {
      this.loading = true
      this.$http.get(`playerProfile/${this.id}/mapping`)
        .then(r => {
          if (!r.data.playerProfileId) {
            r.data.playerProfileId = this.id
          }
          this.map = r.data
          this.mapOg = JSON.parse(JSON.stringify(r.data))
        })
        .finally(() => {
          this.loading = false
        })
    },
    save () {
      this.loading = true
      this.$http.post(`playerProfile/${this.id}/mapping`, this.map)
        .then(r => {
          this.map = r.data
          this.mapOg = JSON.parse(JSON.stringify(r.data))
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.loadMe()
  }
}
</script>
